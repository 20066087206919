.notFound {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: var(--color-primary);
  height: 60vh;
}

.notFound h3 {
  margin-bottom: 40px;
}

@media (max-width: 500px) {
  .notFound p {
    width: 80%;
  }
}
