.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 330px;
  padding: 10px 30px;
  margin-bottom: 30px;
}

.innerContainer {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 16px;
  height: 90px;
}

.title {
  color: var(--color-fifth);
  font-size: 20px;
  font-weight: 700;
  margin-left: 20px;
  text-align: left;
}

.content {
  color: var(--color-fourth);
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  transition: all 0.4s linear;
}

.content.show {
  display: block;
}

.iconControl {
  width: 17px;
}

.button {
  border: none;
  border-radius: 5px;
  margin-top: 5px;
  cursor: pointer;
}

.button:hover {
  background-color: var(--color-secondary);
}

@media (max-width: 500px) {
  .container {
    padding: 10px 0;
  }
  .content {
    display: block;
  }
  .title {
    text-align: left;
  }
  .button {
    display: none;
  }
}
