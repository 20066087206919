.text {
  color: var(--color-fifth);
  font-size: 2.1rem;
  text-align: center;
  margin: 5rem 0 3rem;
}

.small {
  font-size: 1rem;
}

@media (max-width: 500px) {
  .text {
    font-size: 1.5rem;
    margin-top: 180px;
  }
  .small {
    font-size: 0.8rem;
  }
}
