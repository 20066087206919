@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap');

@font-face {
  font-family: 'bahnschrift';
  src: local('bahnschrift'), url(./assets/fonts/BAHNSCHRIFT.TTF);
}

* {
  text-align: justify;
}

body {
  --color-primary: #90979d;
  --color-secondary: #4b565f;
  --color-third: #9db2b2;
  --color-fourth: #747d84;
  --color-fifth: #362b74;
  --color-accent: #8f014f;
  --color-complementary: #729190;
  --color-light: #fff;
  --color-dark: #000;

  --color-success: #12bd4b;

  --color-error: #a10c4a;

  --size-1: 0.25rem;
  --size-2: 0.5rem;
  --size-3: 0.75rem;
  --size-4: 1rem;
  --size-5: 1.25rem;
  --size-6: 1.5rem;
  --size-8: 2rem;
  --size-10: 2.5rem;
  --size-12: 3rem;
  --size-16: 4rem;
  --size-20: 5rem;
  --size-40: 10rem;

  --align-x: 0 10%;

  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'bahnschrift';
}

a {
  text-decoration: none;
}

.specIcon {
  height: 60px;
}
