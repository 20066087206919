.paragraph {
  color: var(--color-fourth);
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  text-align: left;
  width: 100%;
  margin-bottom: 0;
  text-align: center;
}

.quote {
  width: 100%;
  text-align: left;
  color: var(--color-fourth);
  margin-top: 0;
  margin-bottom: 30px;
}

@media (max-width: 700px) {
  .paragraph {
    margin: 0 0 20px 0;
  }
}
