.mobileMenuWrap {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.mobileMenuWrap:hover {
  animation: shake 0.3s linear;
}

.icon {
  width: 30px;
}

@keyframes shake {
  50% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0deg);
  }
}

@media (min-width: 600px) {
  .mobileMenuWrap {
    display: none;
  }
}
